<template>
    <div class="box">
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="list">
            <div class="item" v-for="(item, idx) in list" :key="idx">
                <img v-if="item.picUrl" class="active" :src="item.picUrl" alt="" />
				<img v-else src="@/assets/images/default_pic.png" class="active"/>
                <div class="info">
                    <div class="title">{{ item.name }}</div>
                    <div v-if="item.status == '0' || item.status == '1'" class="go" @click="goDetail(item)">看直播</div>
                    <div v-else class="go" @click="goDetail(item)">看回放</div>
                </div>
            </div>
        </div>
        <el-pagination class="pagination" :current-page="pageNum" :page-size="pageSize" :total="total" @current-change="currentChange" background layout="prev, pager, next,jumper"></el-pagination>
    </div>
</template>

<script>
import { getBroadcastList } from '@/api/broadcast';
export default {
    data() {
        return {
            nav: [
				{
					title: '首页',
                    to: { path: '/' },
				},
                {
                    title: '直播',
                }
            ],
            pageNum: 1,
            pageSize: 12,
            total: 0,
            list: [],
        }
    },
    created() {
        this.getList();
    },
    methods:{
        currentChange(val) {
            //滚动到页面顶部
            window.scrollTo(0, 0);
            this.pageNum = val;
            this.getList();
        },
        goDetail(item) {
            let playerUrl = item.playerUrl;
            if (item.status == '2') {
                playerUrl = item.playbackUrl;
            }
            this.$router.push({ path: '/video', query: { playerUrl: playerUrl }  })
        },
        getList() {
            getBroadcastList({
                pageNum: this.page,
                pageSize: this.limit
            }).then((res) => {
                this.list = res.rows;
                this.total = res.total;
            });
        },
    }
}
</script>

<style lang="less" scoped>
    .box {
        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
                display: flex;
                align-items: center;
                .col {
                    display: flex;
                    align-items: center;
                    margin-right: 24px;
                    span {
                        font-size: 13px;
                        color: #333333;
                    }
                    .el-select {
                        width: 160px;
                    }
                    ::v-deep .el-input__inner {
                        border: 0;
                    }
                }
            }
            .right {
                display: flex;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 24px;
                }
                ::v-deep .el-input__inner,
                ::v-deep .el-input {
                    border-radius: 20px;
                    width: 240px;
                    height: 30px;
                }
                ::v-deep .el-input__prefix {
                    margin-top: -4px;
                }
                ::v-deep .el-input__suffix {
                    margin-top: -4px;
                }
            }
        }

        .list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 16px;
            width: 100%;

            .item {
                display: flex;
                flex-direction: column;
                page-break-inside: avoid;
                width: 100%;
                background: #ffffff;
                border: 1px solid #ebebeb;
                border-radius: 4px;
                margin-top: 24px;
                position: relative;

                .active {
                    width: 100%;
                    height: 210px;
                }

                .info {
                    padding: 12px;

                    .title {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 23px;
                        height: 46px;
                        color: #333333;
                        width: 100%;
                        .ellipsisLine(2);
                    }
                    .status {
                        position: absolute;
                        right: 0;
                        top: 0;
                        font-size: 16px;
                        line-height: 23px;
                        color: #ffffff;
                        padding: 4px 12px;
                        background: #f33f3e;
                        border-radius: 2px 4px;
                    }

                    .address {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        span {
                            font-size: 13px;
                            line-height: 19px;
                            color: #999999;
                        }
                    }
                    .go {
                        margin-top: 10px;
                        height: 44px;
                        box-shadow: 0px 2px 4px rgba(14, 79, 71, 0.2);
                        background: #eda200;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        color: #ffffff;
                    }
                }
            }
        }
        .pagination {
            margin: 20px auto;
            cursor: pointer;
        }
    }
</style>