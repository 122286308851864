import { get } from '@/utils/request.js';

// 获取直播列表 /dev-api/system/broadcast/list
export function getBroadcastList(data) {
    return get({
        url: '/system/broadcast/list',
        token: true,
        data
    });
}
